
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCard.vue";
import axios from "@/api/axios";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Address",
  components: {
    AddressCard,
  },
  setup() {

    const list = ref<object>([]);
    function getList() {
      axios.get("/M/User/Address").then(res => {
          console.log(res)
          list.value = res.data.obj.list;
      }).catch(err => {
          console.log(err)
      })
    }

    getList()

    function handleDel(id: string) {
      Modal({
        title: "温馨提示",
        content: "确认删除?",
        onConfirm: () => {
          const data = qs.stringify({
            id
          });
          axios.post("/M/Server/DoDelAddress", data).then(res => {
              console.log(res)
              Toast({
                type: "success",
                title: "删除成功！",
              });
              getList()
              window.location.reload()
          }).catch(err => {
              console.log(err)
          })
        },
        onCancel: () => {
          console.log('取消')
        },
      });
    }

    return {
      list,
      handleDel
    }
  },
});
